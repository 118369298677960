import React from 'react'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { Link } from 'gatsby'

const ThankYou = () =>  (
  <Layout>
      <Seo title="Thank You" />
      <div className="py-5 text-center">
        <h1 className="display-6 fw-bold">Thank you for taking the time.</h1>
        <div className="col-lg-11 mx-auto pt-3">
          <p className="lead">You'll hear from us soon!</p>
        </div>
        <div className="col-lg-11 mx-auto mt-5">
          <Link className="btn btn-primary" to="/blog">Read our Blog</Link>
        </div>
    </div>
  </Layout>
)

export default ThankYou